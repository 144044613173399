*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

#root{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;

}

body {
    font: 44px "Century Gothic", Futura, sans-serif;
    background-color: #313131;
    color: #fff;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  
  .title{
    font-size: 30px;
    color: aqua;
    width: 297px;
    height: 50px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    background-color: #313131;
    /* border-radius: 13px; */
    /* box-shadow: 0px 3px 10px -3px rgb(93 93 93 / 52%), 6px 0px 10px -7px rgb(145 145 145 / 53%), -3px 0px 2px -7px rgb(255 255 255 / 56%); */
    }

  .restartbtn{
    cursor: pointer;
    padding: 10px;
    border: 0.5px solid #696969;
    border-radius: 10px;
    font-size: 18px;
    color: #68eeff;
    background-color: #414141;
    box-shadow: 0px 0px 5px 3px #00000000;
  }

.restartbtn:focus {
    outline: none;
}

.restartbtn:active {
    transform: translateY(1px);
}


  ol, ul {
    padding-left: 30px;
  }
  
  .board-row:after {
    clear: both;
    content: "";
    display: table;
  }
  
  .status {
    margin-bottom: 35px;
    font-size: 20px;
    padding: 20px;
   
  
  }
  
#top-row .square{
  border-top: none;
}

#bottom-row .square{
  border-bottom: none;
}
#bottom-row {
  border-left: none;
}

.board-row > .square:nth-child(1) {
  border-left: none;
}
.board-row > .square:nth-child(3) {
  border-right: none;
}


.square {
  background: #313131;
  border: 2px solid #999;
  float: left;
  font-size: 70px;
  line-height: 34px;
  height: 100px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 100px;
  color: #efd5b6;
  cursor: pointer;
  }
  
  .square:focus {
    outline: none;
  }
  
  .kbd-navigation .square:focus {
    background: #ddd;
  }
  
  .game {
    background-color: #414141;
    min-width: 350px;
    height: 98vh;
    border: .5px solid #707070;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
  }
  

  .game-board{
    min-width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 25px;
  }

  .gametable{
    margin-bottom: 115px;
  }

.results{
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 356px;
  background: #21a4a4f2;
  width: 320px;
  position: fixed;
  border-radius: 18px;
  box-shadow: 0 9px 10px -3px rgb(0 0 0 / 52%), 8px 0 10px -7px rgb(0 0 0 / 53%), -8px 0 10px -7px rgb(0 0 0 / 46%);
}


 